import React, { Component } from 'react';
import ActionButton from '../component/action-button';
import { ToastSuccess, ToastError } from "../component/toast-notification";
import Carousel from 'react-bootstrap/Carousel';
import { Modal } from "react-bootstrap";
import {
    getAllSettings,
    updateAllSettings,
    updateDashboardAd,
    uploadImage
} from '../api/apiService';

import { connect } from 'react-redux';
import { loader } from '../Actions/index';
import store from '../store';
import { Switch } from '@material-ui/core';
const mapStateToProps = (store) => {
    return {
        loaderDisplay: store.loaderDisplay
    }
}

class AppSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSubmitDImg: false,
            isSubmitExtra: false,
            isSubmitTopImg: false,
            settingsAll: [],
            allDashImg: [],
            adDashboard: null,
            dashImgTitle: "",
            dashImgTxt: "",
            dashImgIsActive: true,
            adExtra: null,
            adImg: null,
            adTop: null,
            modalShow: false,
        }
        this.allSettings();

    }

    //  modal close and open

    handleModalclose = () => {
        this.setState({ modalShow: !this.state.modalShow, allDashImg: this.state.settingsAll.dashboardAd })
    }
    /* 
    * Dashboard Image And
    * Extra Ad Image File 
    * Event
    */
    adImageChange = (event, imgType) => {
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            if (imgType === "dashboardImg") {
                this.setState({
                    adDashboard: URL.createObjectURL(img),
                    adImg: img
                }, () => {
                    return this.state.isSubmitDImg ? this.valid("dashboardImg") : false;
                })
            }
            else if (imgType === "extrAd") {
                this.setState({
                    adExtra: URL.createObjectURL(img),
                    adImg: img
                }, () => {

                    return this.state.isSubmitExtra ? this.valid("extrAd") : false;
                })
            }
            else if (imgType === "topImg") { // New logic for Home Page Top Image
                this.setState({
                    adTop: URL.createObjectURL(img),
                    adImg: img
                }, () => {
                    return this.state.isSubmitTopImg ? this.valid("topImg") : false;
                });
            }
        }
    }
    /* Form 
    * Input Event
    */
    handleChangeTitle = (e) => {
        this.setState({ dashImgTitle: e.target.value }, () => {
            return this.state.isSubmitDImg ? this.valid("dashboardImg") : false;
        })
    }
    handleChangeTxt = (e) => {
        this.setState({ dashImgTxt: e.target.value }, () => {
            return this.state.isSubmitDImg ? this.valid("dashboardImg") : false;
        })
    }
    handleChangeStatus = (e) => {
        this.setState({ dashImgIsActive: e.target.value }, () => {
            return this.state.isSubmitDImg ? this.valid("dashboardImg") : false;
        })
    }

    // ---- Validation ------
    valid = (type) => {
        let checked = true;
        if (type === "dashboardImg") {
            if (this.state.adDashboard === null) {
                checked = false;
            }
            if (this.state.dashImgTitle === "") {
                checked = false;
            }
            if (this.state.dashImgTxt === "") {
                checked = false;
            }
            if (this.state.dashImgIsActive === null) {
                checked = false;
            }
        }
        if (type === "extrAd") {
            if (this.state.adExtra === null) {
                checked = false;
            }
        }
        if (type === "topImg") { // New validation for Home Page Top Image
            if (this.state.adTop === null) {
                checked = false;
            }
        }
        return checked
    }
    /*
    * submit button All Ad Image form
    */
    submitAdImg = (type) => {
        if (type === "dashboardImg") {
            this.setState({ isSubmitDImg: true });
            if (this.valid("dashboardImg")) {
                this.uploadAdImage("dashboardImg")
            }
        }
        if (type === "extrAd") {
            this.setState({ isSubmitExtra: true });
            if (this.valid("extrAd")) {
                this.uploadAdImage("extrAd")
            }
        }
        if (type === "topImg") { // New submission logic for Home Page Top Image
            this.setState({ isSubmitTopImg: true });
            if (this.valid("topImg")) {
                this.uploadAdImage("topImg");
            }
        }
    }
    /*
    * Reset button All Ad Image form
    */
    submitBtnReset = (type) => {
        if (type === "dashboardImg") {
            this.setState({
                isSubmitDImg: false,
                adDashboard: null,
                dashImgTitle: "",
                dashImgTxt: "",
                dashImgIsActive: true

            });
        }
        if (type === "extrAd") {
            this.setState({
                isSubmitExtra: false,
                adExtra: null,
            }, () => {
                if (this.state.adExtra === null) {
                    document.getElementById("file2").value = ""
                }
            });
        }
        if (type === "topImg") { // New reset logic for Home Page Top Image
            this.setState({
                isSubmitTopImg: false,
                adTop: null,
            });
        }
    }
    /*
    * button modal
    * Delete Dasboard Image 
    */
    deleteDashImg = (e) => {
        if (window.confirm("Are you delete dashboard ad image")) {
            this.setState({
                allDashImg: this.state.allDashImg.filter(item => item._id !== e._id)
            }, () => {
                console.log(this.state.allDashImg);
                // document.getElementById(e._id).checked = false;
            })
        }
    }
    chaangeDashImgStatus = (item) => () => {  // Return a function instead of executing immediately
        if (window.confirm("Are you sure to change the status of the dashboard ad image")) {
            const updatedDashImg = this.state.allDashImg.map(img => {
                if (img._id === item._id) {
                    return { ...img, isActive: !img.isActive };
                }
                return img;
            });

            this.setState({
                allDashImg: updatedDashImg
            });
        }
    }
    /*
    * button modal
    * Save Dasboard Image 
    */
    modalDashImgSet = () => {
        this.changeDashboardAd(this.state.allDashImg)
        this.setState({ modalShow: false })
    }
    /*
    * Child
    * Component to function call
    */
    parentFunctionCall = (e) => {
        const data = {
            "autoSubmit": this.state.settingsAll.autoSubmit,
            "extraAd": this.state.settingsAll.extraAd
        }
        this.updateSettings(data)
    }
    // Api call /////////////////
    /*
    * Api
    * Get All Settings
    */
    allSettings = async () => {
        store.dispatch(loader(true));
        try {
            const settings = await getAllSettings();
            this.setState({
                settingsAll: settings.data.data,
                allDashImg: settings.data.data.dashboardAd,
            });
            store.dispatch(loader(false));
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    /*
    * Api
    * Upload Image
    */
    // uploadAdImage = async (type) => {
    //     store.dispatch(loader(true));
    //     if (type === "dashboardImg") {
    //         try {
    //             const loadImg = await uploadImage(this.state.adImg, "ad");
    //             let imgData = this.state.settingsAll.dashboardAd
    //             const data = [
    //                 {
    //                     "title": this.state.dashImgTitle,
    //                     "text": this.state.dashImgTxt,
    //                     "imageUrl": loadImg.data.data.location,
    //                     "isActive": true
    //                 }
    //             ]
    //             for (let i = 0; i < imgData.length; i++) {
    //                 let allImg = {
    //                     "title": imgData[i].title,
    //                     "text": imgData[i].text,
    //                     "imageUrl": imgData[i].imageUrl,
    //                     "isActive": imgData[i].isActive
    //                 }
    //                 data.push(allImg)
    //             }
    //             this.changeDashboardAd(data);
    //         } catch (ex) {
    //             store.dispatch(loader(false));
    //             console.log(ex);
    //             ToastError(ex.message)
    //         }
    //     }
    //     if (type === "extrAd") {
    //         try {
    //             const loadImg = await uploadImage(this.state.adImg, "ad");
    //             const data = {
    //                 "autoSubmit": this.state.settingsAll.autoSubmit,
    //                 "extraAd": loadImg.data.data.location
    //             }
    //             this.updateSettings(data);
    //         } catch (ex) {
    //             store.dispatch(loader(false));
    //             console.log(ex);
    //             ToastError(ex.message)

    //         }
    //     }
    // }
    /* Upload Image logic */
    uploadAdImage = async (type) => {
        store.dispatch(loader(true));
        try {
            const loadImg = await uploadImage(this.state.adImg, "ad");
            if (type === "dashboardImg") {
                let imgData = this.state.settingsAll.dashboardAd;
                const data = [
                    {
                        "title": this.state.dashImgTitle,
                        "text": this.state.dashImgTxt,
                        "imageUrl": loadImg.data.data.location,
                        // "isActive": true
                        isActive: this.state.dashImgIsActive
                    }
                ];
                for (let i = 0; i < imgData.length; i++) {
                    let allImg = {
                        "title": imgData[i].title,
                        "text": imgData[i].text,
                        "imageUrl": imgData[i].imageUrl,
                        "isActive": imgData[i].isActive
                    };
                    data.push(allImg);
                }
                this.changeDashboardAd(data);
            }
            if (type === "extrAd") {
                const data = {
                    "autoSubmit": this.state.settingsAll.autoSubmit,
                    "extraAd": loadImg.data.data.location
                };
                this.updateSettings(data);
            }
            if (type === "topImg") { // New upload logic for Home Page Top Image
                const data = {
                    "autoSubmit": this.state.settingsAll.autoSubmit,
                    "topImage": loadImg.data.data.location
                };
                this.updateSettings(data);
            }
        } catch (ex) {
            store.dispatch(loader(false));
            console.log(ex);
            ToastError(ex.message);
        }
    }
    /*
    * Api
    * Update Dashboard Ad
    */
    changeDashboardAd = async (data) => {
        store.dispatch(loader(true));

        try {
            const settings = await updateDashboardAd(data);
            console.log(settings.data.data);
            this.allSettings();
            this.submitBtnReset("dashboardImg")
            store.dispatch(loader(false));
            ToastSuccess(settings.data.message)
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    /*
    * Api
    * Update All Settings
    */
    updateSettings = async (data) => {
        store.dispatch(loader(true));

        try {
            const settings = await updateAllSettings(data);
            this.allSettings();
            this.submitBtnReset("extrAd")
            this.submitBtnReset("topImg")
            store.dispatch(loader(false));
            ToastSuccess("Update Settings")
        } catch (ex) {
            console.log(ex);
            store.dispatch(loader(false));
        }
    }
    render() {
        return (
            <>
                {this.state.settingsAll.length === 0 ?
                    <div></div>
                    :
                    <div className="container-fluid">
                        <div className="my-3">
                            <div className="row">
                                <div className=" col-sm-1" />
                                <div className='col-sm-5'>
                                    <div className="category-container mt-1">
                                        <div style={{ display: 'flex', minWidth: '100%', justifyContent: 'space-between' }}>
                                            <h5 className="text-secondary">Dashboard Image</h5>
                                            <span onClick={() => this.setState({ modalShow: true })} style={{ cursor: 'pointer', color: 'red' }}>
                                                View All
                                            </span>
                                        </div>
                                        <div className="ad-container">
                                            <Carousel>
                                                {this.state.settingsAll.dashboardAd.map(item => {
                                                    return (
                                                        <Carousel.Item interval={1500} key={item._id}>
                                                            <img
                                                                className="d-block w-100"
                                                                style={{ height: "130px" }}
                                                                src={item.imageUrl}
                                                                alt="logo"
                                                            />
                                                            <div className="ad-caption"
                                                                onClick={() => this.setState({ modalShow: true })}
                                                            >
                                                                <div className="carousel-link">
                                                                    <span>View all</span>
                                                                </div>
                                                            </div>
                                                        </Carousel.Item>
                                                    );
                                                })}
                                            </Carousel>
                                            <div className="d-flex justify-content-sm-between p-2">
                                                <div>
                                                    <label className="upload-image-container cursor-pointer mr-2" htmlFor="file1">
                                                        <img src={this.state.adDashboard} alt=""
                                                            style={this.state.adDashboard === null && this.state.isSubmitDImg ? { border: "1px solid red" } : {}}
                                                            className="upload-image" />
                                                        <i style={this.state.adDashboard !== null ? { color: "transparent" } : {}}
                                                            className="demo-icon icon-camera">&#xe807;</i>
                                                        <input type="file" id="file1" accept="image/*" onChange={(e) => this.adImageChange(e, "dashboardImg")} />
                                                    </label>
                                                </div>
                                                <div className="text-right">
                                                    <div className="form-group mb-2">
                                                        <input
                                                            onChange={(e) => this.handleChangeTitle(e)}
                                                            style={this.state.dashImgTitle === "" && this.state.isSubmitDImg ? { border: "1px solid red" } : {}}
                                                            type="text"
                                                            value={this.state.dashImgTitle}
                                                            className="form-control"
                                                            placeholder="Title"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <input
                                                            onChange={(e) => this.handleChangeTxt(e)}
                                                            style={this.state.dashImgTxt === "" && this.state.isSubmitDImg ? { border: "1px solid red" } : {}}
                                                            type="text"
                                                            value={this.state.dashImgTxt}
                                                            className="form-control"
                                                            placeholder="Text"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <select className='form-control' onChange={(e) => this.handleChangeStatus(e)}>
                                                            <option value='true'>Active</option>
                                                            <option value='false'>Inactive</option>
                                                        </select>
                                                    </div>
                                                    <div className="d-flex justify-content-sm-between">
                                                        <button className="btn btn-cancel m-0"
                                                            onClick={() => this.submitBtnReset("dashboardImg")}
                                                        >Reset</button>
                                                        <button className="btn btn-verify"
                                                            onClick={() => this.submitAdImg("dashboardImg")}
                                                        >Add</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Extra ad section */}
                                <div className='col-sm-5'>
                                    <div>
                                        <div className="category-container mt-1">
                                            <h5 className="text-secondary">Extra Ad Image</h5>
                                            <div className="ad-container">
                                                {/* <img
                                            className="img-fluid"
                                            style={{ height: "130px", }}
                                            src={this.state.settingsAll.extraAd}
                                            alt="Image One"
                                        /> */}
                                                <label className="extra-ad-sec cursor-pointer m-0" htmlFor="file2">
                                                    <img src={this.state.adExtra !== null ? this.state.adExtra : this.state.settingsAll.extraAd} alt=""
                                                        style={this.state.adExtra === null && this.state.isSubmitExtra ? { border: "1px solid red", height: "130px" } : { height: "130px" }}
                                                        className="d-block w-100" />
                                                    <input type="file" id="file2" accept="image/*" onChange={(e) => this.adImageChange(e, "extrAd")} />
                                                </label>
                                                {this.state.adExtra !== null ?
                                                    <div className="text-right p-2">
                                                        <button type="reset" className="btn btn-cancel m-0 mr-2"
                                                            onClick={() => this.submitBtnReset("extrAd")}
                                                        >Reset</button>
                                                        <button className="btn btn-verify"
                                                            onClick={() => this.submitAdImg("extrAd")}
                                                        >Add</button>
                                                    </div>
                                                    :
                                                    <div></div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* Home Page Top Image section */}
                                    <div className="category-container mt-1">
                                        <h5 className="text-secondary">Home Page Top Image</h5>
                                        <div className="ad-container">
                                            <label className="extra-ad-sec cursor-pointer m-0" htmlFor="file3">
                                                <img src={this.state.adTop !== null ? this.state.adTop : this.state.settingsAll.topImage} alt=""
                                                    style={this.state.adTop === null && this.state.isSubmitTopImg ? { border: "1px solid red", height: "130px" } : { height: "130px" }}
                                                    className="d-block w-100" />
                                                <input type="file" id="file3" accept="image/*" onChange={(e) => this.adImageChange(e, "topImg")} />
                                            </label>
                                            {this.state.adTop !== null ?
                                                <div className="text-right p-2">
                                                    <button type="reset" className="btn btn-cancel m-0 mr-2"
                                                        onClick={() => this.submitBtnReset("topImg")}
                                                    >Reset</button>
                                                    <button className="btn btn-verify"
                                                        onClick={() => this.submitAdImg("topImg")}
                                                    >Add</button>
                                                </div>
                                                :
                                                <div></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Dashboard image Modal */}
                            <Modal
                                show={this.state.modalShow}
                                onHide={this.handleModalclose}
                                backdrop="static"
                                size="m"
                                keyboard={false}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Dashboard Images</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <div className="setting-img-box">
                                            {this.state.allDashImg.map((m) => {
                                                return (
                                                    <div key={m._id} className=" mb-3" style={{ display: 'flex', justifyContent: 'space-between', width: "100%", height: "120px" }}>
                                                        <img className="card-img" style={{ width: '85%' }} src={m.imageUrl} alt="Card logo" />
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div onClick={() => this.deleteDashImg(m)}>
                                                                <ActionButton>
                                                                    <i className="demo-icon icon-delete">&#xe80e;</i>
                                                                </ActionButton>
                                                            </div>
                                                            <div>
                                                                <Switch checked={m.isActive} onClick={this.chaangeDashImgStatus(m)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div className="text-right">
                                            <button className="btn btn-verify"
                                                onClick={() => this.modalDashImgSet()}
                                            >Save</button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default connect(mapStateToProps)(AppSettings);