import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppSettings from './AppSettings'
import AppImages from './appImages';
import SubscriptionPlan from './subscriptionPlan';
import OrderSettings from './OrderSettings';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={2}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
export default function OffersPage(props) {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className="mt-4">
            <div className="px-4">
                <AppBar position="static" color="default">
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="full width tabs example"
                    >
                        <Tab label="App Images" {...a11yProps(0)} />
                        <Tab label="App Settings" {...a11yProps(1)} />
                        <Tab label="Order Settings" {...a11yProps(2)} />
                        <Tab label="Subscription Plan" {...a11yProps(3)} />
                    </Tabs>
                </AppBar>
            </div>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <AppImages />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <AppSettings />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <OrderSettings />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <SubscriptionPlan />
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}



